import { Image, ImageFormat } from '@mechhive/react';
import clsx from 'clsx';
import { useTranslate } from '~/hooks/useTranslate';
import { useTrustpilotData } from '~/hooks/useTrustpilotData'
import { useUserAccount } from '~/hooks/useUserAccount';

type TrustpilotBannerProps = {
  className?: string;
}

export const TrustpilotBanner = ( { className } : TrustpilotBannerProps ) => {
  const { score, totalReviews, starsImage, enabled } = useTrustpilotData();
  const t = useTranslate();
  const userAccount = useUserAccount();
  if ( enabled !== true || userAccount != null ) {
    return null;
  }

  return (
    <a
      href={ 'https://trustpilot.com/review/www.rewarble.com' }
      target='_blank'
      className={ clsx(
        'flex flex-col items-center gap-2',
        className
      ) }
      rel="noreferrer">
      <Image
        provider={ 'images' }
        src={ '/trustpilot/logo.png' }
        sizes={ [
          {
            width: 86,
            height: 20
          }
        ] }
        width={ 86 }
        height={ 20 }
        dprVariants={ [1,2] }
        formats={ [
          ImageFormat.WebP,
          ImageFormat.PNG
        ] }
        alt='Trustpilot Logo'
      />
      <div className={ 'flex flex-row gap-1' }>
        <Image
          provider={ 'images' }
          src={ `/trustpilot/stars-${starsImage}.svg` }
          sizes={ [
            {
              width: 152,
              height: 28
            }
          ] }
          width={ 152 }
          height={ 28 }
          dprVariants={ [1,2] }
          formats={ [
            ImageFormat.WebP,
            ImageFormat.PNG
          ] }
          alt='Trustpilot Stars'
        />
      </div>
      <div className='flex flex-row items-center'>
        <p className={ 'text-xs' }>TrustScore <span className={ 'font-semibold text-sm' }>{ score }</span></p>
        <div className={ 'w-px h-3.5 bg-[#DCDCE6] mx-2' } />  
        <p className={ 'underline text-sm' }>{ totalReviews } {t( 'TRUSTPILOT_REVIEWS' )}</p>
      </div>
    </a>
  )
}
